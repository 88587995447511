import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
const tweet = "Check out The Master List, a list of the most useful crowdsourced lists, documents and spreadsheets: https://themasterlist.org/";


class SharePage extends React.Component {

	sendClickEvent(element) {
		element = element || "unknown";
		element = "sharepage-" + element;
		// console.log("Clicked on", element);
		if (window.ga) {
			window.ga(`send`, `event`, {
				eventCategory: `sharepage`,
				eventAction: `click`,
				eventLabel: element,
				hitCallback: function() {}
			});
		}
	}

	render() {
		const { location } = this.props;
		return (
			<Layout>
				<SEO
					location={location}
					title="Share"
				/>
				<div className="page-content share-page">
					<h2>Tell your friends and colleagues about The Master List:</h2>
					<div className="protip">Pro Tip: You can also share it to a group</div>
					<div className="links">
						<a onClick={() => this.sendClickEvent('share-to-facebook')} className="link share facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthemasterlist.org%2F">
							<svg className="icon icon32"><use xlinkHref="#social-facebook" /></svg>
							<span className="title">Share on Facebook</span>
						</a>
						<a onClick={() => this.sendClickEvent('share-to-linkedin')} className="link share linkedin" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//themasterlist.org/">
							<svg className="icon icon32"><use xlinkHref="#social-linkedin" /></svg>
							<span className="title">Share on LinkedIn</span>
						</a>
						<a onClick={() => this.sendClickEvent('share-to-twitter')} className="link share twitter" target="_blank" rel="noopener noreferrer" href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweet)}>
							<svg className="icon icon32 twitter"><use xlinkHref="#social-twitter" /></svg>
							<span className="title">Share on Twitter</span>
						</a>
					</div>
				</div>
			</Layout>
		);
	}
};

export default SharePage;
